.LinearFinalizaVenda {
    font-weight: 700 !important;
    /* animation: fade 3s cubic-bezier(0,.92,1,.04)infinite; */
    position: relative;
}

.LinearFinalizaVenda::after {
    content: '';
    animation: dotdotdot 1.5s steps(4) infinite;
    position: absolute;
}

@keyframes dotdotdot {

    0% {
        content: '';
    }

    25% {
        content: '.';
    }

    50% {
        content: '..';
    }

    75% {
        content: '...';
    }

}