@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --laranja: #FF7F01;
  --laranja-claro: #DDA15E;
  --laranja-hover: #D85D00;
  /* --sla: #8100ff --laranja: #F73AA9; */
  --bg: linear-gradient(38deg, rgba(83, 58, 180, 1) 0%, rgba(196, 43, 81, 1) 35%, rgb(255 152 80) 66%, rgba(252, 176, 69, 1) 100%);
  --bg: linear-gradient(23deg, rgb(226 219 255) 0%, rgb(255 252 232) 100%);
  --bg: linear-gradient(23deg, rgb(255 127 0) 0%, rgb(255 159 71) 100%);
  /* --font: #3e1859; */
  /* --font: #183513; */
  --font: #fff;
  --error: #e71010;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glassmorphism {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.015);
  backdrop-filter: blur(15px);
}

.snack {
  width: 400px;
  max-width: 100%;
  flex-wrap: nowrap !important;
}

@media screen and (max-width: 600px) {
  .snack {
    width: auto;
  }
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}


/** {
  outline: 1px solid red !important;
}*/
