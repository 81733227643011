.h4-formatado {
    margin: 0;
    padding: 0;
    color: rgb(122, 116, 116);
    font-size: 0.85rem;
}

.h4-formatado {
    margin: 0;
    padding: 0;
    color: rgb(122, 116, 116);
    font-size: 0.85rem;
}

.color-orange {
    border: 2px solid var(--laranja);
    color: #BC6C25;
}

.card-totalizador-pedido {
    border: 2px solid var(--laranja);
    height: 6.9rem;
}

.card-totalizador-pedido .valor-totalizador {
    color: #df6e0b;
}

.card-totalizador-pedido .label-totalizador {
    color: rgb(122, 116, 116);
    font-size: 18px;
}

.card-totalizador-pedido-troca {
    border: 2px solid #FF9F9F;
}

.card-totalizador-pedido .total-itens-troca {
    font-size: 1.05rem;
    color: rgb(122, 116, 116);
}

.atalho-rodape span {
    position: absolute;
    left: 50%;
    right: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
}

* {
    scrollbar-width: auto;
    scrollbar-color: var(--laranja) #bfbfbf;
}


* ::-webkit-scrollbar {
    width: 16px;
}

* ::-webkit-scrollbar-track {
    background: #FFFFFF;
}

* ::-webkit-scrollbar-thumb {
    background-color: var(--laranja);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.footerText {
    font-size: 18px;
    font-family: 'Work Sans', sans-serif;
    color: #525252;
    font-weight: 700;
}

.glassMorphism {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    border-top: 1px solid rgba(255, 255, 255, 0.18);
}

.MuiAutocomplete-endAdornment>.MuiButtonBase-root>.MuiSvgIcon-root {
    visibility: visible;
}

.isVisualizar {
    border: 3px solid #9F9F9F;
    scrollbar-color: #dbd8d8 #9F9F9F;
}