:root {
    --lineSize: 3px;
    --lineColor: #FFF;
}

@keyframes animateTop {
    to {
        transform: translateX(-100%);
    }

    from {
        transform: translateX(100%);
    }
}

@keyframes animateRight {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(-100%);
    }
}

@keyframes animateBottom {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes animateLeft {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(100%);
    }
}

.animated-button {
    transform: translate(0%, 0%);
    overflow: hidden;
    border-radius: 6px;
}

.animated-button span.line {
    position: absolute;
    z-index: 101;
}

.animated-button span.line:nth-child(1) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: var(--lineSize);
    background: var(--lineColor);
    animation: 2s animateTop linear infinite;
}

.animated-button span.line:nth-child(2) {
    top: 0px;
    right: 0px;
    height: 100%;
    width: var(--lineSize);
    background: var(--lineColor);
    animation: 2s animateRight linear -1s infinite;
}

.animated-button span.line:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: var(--lineSize);
    background: var(--lineColor);
    animation: 2s animateBottom linear infinite;
}

.animated-button span.line:nth-child(4) {
    top: 0px;
    left: 0px;
    height: 100%;
    width: var(--lineSize);
    background: var(--lineColor);
    animation: 2s animateLeft linear -1s infinite;
}

/* animation troca de botão vale presente -> troca referenciada */
#vale-presente-btn,
#troca-referenciada-btn {
    transform: scaleX(1);
    transform-origin: right;
}

@keyframes shrink {
    to {
        transform: scaleX(0);
    }
}

@keyframes grow {
    from {
        transform: scaleX(0);
    }
}

.shrink {
    animation: shrink 0.15s forwards ease-out;
}

.grow {
    animation: grow 0.15s forwards ease-in-out;
}
