.helper-container {
    height: 100vh;
    width: auto;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    transition: transform .3s ease-in-out;
    transform: translateX(-160px);
    z-index: 10;
}

.helper-container.open {
    transform: translateX(0);
}

.helper-content {
    width: 160px;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: space-around;
    justify-content: center;
    color: #FFF;
    background-color: var(--laranja);
    z-index: 10;
}

.btn {
    box-sizing: border-box;
    display: block;
    border: 0;
    background-color: var(--laranja);
    width: 50px;
    height: 70px;
    border-radius: 0 50% 50% 0;
    position: relative;
    cursor: pointer;
    color: #FFF;
}

.btn::before,
.btn::after {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: -100%;
    left: 0;
    background-color: transparent;
    width: 55px;
    height: 70px;
    border-bottom-left-radius: 50%;
    box-shadow: -30px 0 0 0 var(--laranja);
    cursor: auto;
}

.btn::after {
    top: 100%;
    border-bottom-left-radius: 0%;
    border-top-left-radius: 50%;
    box-shadow: -30px 0 0 0 var(--laranja);
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: .2s linear;
}

.helper-container .icon:first-child,
.helper-container:not(.open) .icon:last-child {
    transform: translate(-50%, -50%) scale(1);
}

.helper-container:not(.open) .icon:first-child,
.helper-container .icon:last-child {
    transform: translate(-50%, -50%) scale(0);
}
