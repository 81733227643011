.menu-items-container {
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    max-height: 0;
}

.menu-items-container.opened {
    max-height: 150px;
}

.menu-items-container.closed {
    max-height: 0;
}