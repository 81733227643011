#bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    object-fit: cover;
    z-index: 1;
}

#nome>span {
    font-weight: 500;
    color: var(--laranja);
    background-image: url('/public/assets/bgLogo.png');
    background-clip: text;
    background-size: contain;
    background-repeat: repeat;
    background-position: center;
    color: rgba(253, 135, 0, 0.24);
}

form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

#logo {
    height: 15px;
    margin-right: 10px;
    transition: filter .3s ease-in-out;
}
